import { LANGUAGE_AR, LANGUAGE_EN } from '../../constants';

export const detectLanguage = (text: string): string => {
  const arabicPattern = /[\u0600-\u06FF]/;
  const englishPattern = /[A-Za-z]/;

  if (arabicPattern.test(text)) return LANGUAGE_AR;

  if (englishPattern.test(text)) return LANGUAGE_EN;

  return LANGUAGE_AR;
};
