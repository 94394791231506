import config from 'common/config';
import { logger } from 'common/utils';
import { detectLanguage } from './detectLanguage';

const paciEndpointURL = config.common.paciEndpoint;

const paciParams = {
  xmax: String(48.654321),
  ymax: String(29.654321),
  xmin: String(47.123456),
  ymin: String(28.123456),
  f: 'pjson',
};

const paciSeachAutoCompleteEndpoint = `${paciEndpointURL}/KF3SearchAutoComplete/MapServer/exts/PACISOE/SearchAutoComplete`;
const paciSearchEndpoint = `${paciEndpointURL}/KF3Search/MapServer/exts/PACISOE/Search`;

export const autoSearchPaci = async (searchValue: string): Promise<any> => {
  const lang = detectLanguage(searchValue);
  try {
    const response = await fetch(
      `${paciSeachAutoCompleteEndpoint}?${new URLSearchParams({
        ...paciParams,
        searchtext: searchValue,
        language: lang,
      }).toString()}`,
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return { result: data.Result, resultLanguage: lang };
  } catch (error) {
    logger.error({ error: 'PACI error, fetching area details by auto-search', extraData: error });
    return null;
  }
};

export const fetchAreaDetails = async (areaTitle: string): Promise<any> => {
  const lang = detectLanguage(areaTitle);
  try {
    const response = await fetch(
      `${paciSearchEndpoint}?${new URLSearchParams({
        ...paciParams,
        searchtext: areaTitle,
        language: lang,
      }).toString()}`,
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();

    return data.Result[0];
  } catch (error) {
    logger.error({ error: 'PACI error, fetching specific area details', extraData: error });
    return null;
  }
};

export const fetchAreaDetailsByCoordinates = async (lat: number, lng: number, lang: string): Promise<any> => {
  try {
    const response = await fetch(
      `${paciSearchEndpoint}?${new URLSearchParams({
        ...paciParams,
        searchtext: `${lat},${lng}`,
        language: lang,
      }).toString()}`,
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();

    return data.Result[0];
  } catch (error) {
    logger.error({ error: 'PACI error, fetching specific area details', extraData: error });
    return null;
  }
};
