import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import 'leaflet/dist/leaflet.css';
import { LatLngTuple } from 'leaflet';

import { StyledMapContainerWrapper } from './style';

const MapContainer = dynamic(() => import('react-leaflet').then(module => module.MapContainer), {
  ssr: false,
});

const MapTile = dynamic(() => import('./MapTile'), {
  ssr: false,
});

type OpenStreetMapTypes = {
  center: { lat: number; lng: number };
  onDragEnd?: (coords: { lat: number; lng: number }) => void;
  hasAutoComplete?: boolean;
  hasZoomControl?: boolean;
  hasCurrLocationButton?: boolean;
  isStatic?: boolean;
  isBlackAndWhite?: boolean;
  zoom?: number;
};

const OpenStreetMap: React.FC<OpenStreetMapTypes> = ({
  center,
  onDragEnd = () => ({}),
  hasAutoComplete = true,
  hasZoomControl = true,
  hasCurrLocationButton = true,
  isStatic = false,
  isBlackAndWhite = false,
  zoom,
}) => {
  const [mapCenter, setMapCenter] = useState<LatLngTuple>([center.lat, center.lng]);

  return (
    <StyledMapContainerWrapper>
      <MapContainer
        center={mapCenter}
        zoom={zoom}
        zoomControl={false}
        attributionControl={false}
        style={{ height: '100%', width: '100%' }}
        dragging={!isStatic}
      >
        <MapTile
          originalCenter={center}
          center={mapCenter}
          setCenter={setMapCenter}
          onDragEnd={onDragEnd}
          hasAutoComplete={hasAutoComplete}
          hasZoomControl={hasZoomControl}
          hasCurrLocationButton={hasCurrLocationButton}
          isStatic={isStatic}
          isBlackAndWhite={isBlackAndWhite}
          zoom={zoom}
        />
      </MapContainer>
    </StyledMapContainerWrapper>
  );
};

export default OpenStreetMap;
