import styled from 'styled-components';

export const StyledAction = styled.div`
  padding: ${({ theme }) => theme.space[4]}px;
`;

export const StyledMapContainerWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const StyledMapTileWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const StyledAutoCompleteWrapper = styled.div`
  position: absolute;
  width: 80%;
  z-index: 400;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
`;

export const StyledCurrLocationButtonWrapper = styled.div`
  position: absolute;
  z-index: 400;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  background-color: white;
`;

export const AutoCompleteInputWrapper = styled.div`
  position: relative;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  outline: none;
  border: none;
  background-color: white;
  padding: 0 16px;
  box-shadow: 0px 4px 8px -1px rgba(0, 0, 0, 0.08), 0px 2px 4px -1px rgba(27, 32, 50, 0.04);
  display: flex;

  & input {
    background: transparent;
    width: 100%;
    border: none;
    padding: 0 8px;
    // The minimum font size required by Safari to stop auto-zooming
    font-size: 16px;
    &:focus {
      outline: none;
    }
  }
`;

export const AutoCompleteListWrapper = styled.ul`
  position: absolute;
  width: 100%;
  list-style: none;
  padding: ${({ theme }) => theme.space[2]}px 0;
  top: 100%;
  background-color: white;
  left: 0;
  border-radius: ${({ theme }) => theme.space[2]}px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[2]}px;
`;

export const AutoCompleteItemWrapper = styled.div`
  border-radius: ${({ theme }) => theme.space[2]}px;
  padding: ${({ theme }) => theme.space[1]}px ${({ theme }) => theme.space[2]}px;
  margin: 0 ${({ theme }) => theme.space[2]}px;
  &:hover {
    background-color: ${({ theme }) => theme.color.grey1};
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
